.hero {
  height: 100vh;
  max-height: 100vh;
  text-align: center;
  position: relative;
  
  // .hero__content
  &__content {
    margin-top: 20px;
  }

  // .hero__video
  &__video {
    max-width: 60%;
  }

  // .hero__cta
  &__cta { 
    margin-top: 2em;
  }

  // .hero__main 
  &__main {
    position: relative;
    z-index: 100;
  }

  &__body {
    margin-bottom: 10vh;
    position: relative;
  }

  // .hero__image
  &__image {
    display: inline-block;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
    
  // .hero__image--wrap
    &--wrap {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }


  // .hero__image--shadow
  &--shadow {
    
      // .hero__image--shadow::before
      &:before {
        content: ' ';
        display: block;
        height: 50%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 50;
        background: rgba(0,0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 80%);
      }
    }
  }

  // .hero__counter
  &__counter {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.counter {

  &__numbers {
    height: 40px;
    position: relative;
    transform: rotate(-90deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    line-height: 40px;
    font-family: $font-family-poppins-regular;

    &.color--white,
    .color--white & {
      color: $color-white;
    }
  }

  &:after {
    content: ' ';
    display: block;
    height: 70%;
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 100;
    margin-left: -0.5px;
    margin-top: 60px;

    background-color: $color-black;

    .color--white & {
      background-color: $color-white;
    }

  }
}
