.methods {

  & + .methods {
    margin-top: 150px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    opacity: 0.3;
    cursor: pointer;
    transition: $tabs-animation-duration $tabs-animation-timing-function;

    > .header__heading {
      padding: 0 0 0 $grid-gutter-width;
      width: 70%;
    }

    &.open,
    &.opening,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__content {
    position: relative;
  }

  &__body {
    height: 100%;
    min-height: 100%;
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: none;

    &--active {
      // display: block;
    }
  }

  &__number {
    width: 30%;
    position: relative;
    font-size: calculateFontSize($font-size-base, $font-size-computed, 18);
    line-height: calculateFontSize($font-size-base, $font-size-computed, 40);
    text-align: right;
    
    > span {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      background-color: white;
      padding-right: 8px;
      transform: translate(0, 0);

      .open &,
      .opening & {
        right: 100%;
        transform: translate(32px, 0px);
      }
    }

    &::before {
      content: ' ';
      display: block;
      width: 0.01%;
      height: 1px;
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 50;
      background: $black;

      .open &,
      .opening & {
        width: 100%;
      }
    }
  }

  &__image {
    margin-bottom: 20px;
  }
}

.tabs__content {
  
}
.sticky {
  // will-change: min-height;

  // &.is-affixed {
  //   position: fixed;
  //   top: 0;
  // }

  &__fixed {
    transform: none!important;
    // top: inherit!important;
    // position: sticky!important;
    // will-change: position, transform;
  }
}