.footer {
  @include black-background();
  font-size: calculateFontSize($font-size-base, $font-size-computed, 13);
  padding-bottom: 10vh;

  &__author, 
  &__meta-nav {

    color: rgba($color-white, 0.4);

    a {
      color: rgba($color-white, 0.4);
    }
  }
}