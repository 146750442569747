@import './mixin/make-columns';

// Media queries
/* @include respond-to('mobile') {} */
@mixin respond-to($breakpoint) {
  @if $breakpoint=="mobile" {
    @media (max-width: 480px) {
      @content;
    }
  }

  @else if $breakpoint=="tablet" {
    @media (max-width: 769px) {
      @content;
    }
  }

  @else if $breakpoint=="desktop" {
    @media (max-width: 969px) {
      @content;
    }
  }

  @else if $breakpoint=="wrapper" {
    @media (max-width: 1170px) {
      @content;
    }
  }

  @else {
    @media ($breakpoint) {
      @content;
    }
  }
}


// Centering
/*
.parent {
position: relative;
}
.child {
@include centered;
}
*/
@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Convert px to rem
@function rem($size) {
  $rem-size: $size / 16;
  @return #{$rem-size}rem;
}


// Nice underline effect.
// $scale-x basically means visibility, 0 for hidden (will come in on hover), or 1 to be visible from the start.
@mixin nice-underline ($color, $hover-color, $scale-x) {
  position: relative;
  text-decoration: none;

  &::before {
    background: $color;
    bottom: -2px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX($scale-x);
    transition: transform .3s cubic-bezier(.4, 0, .2, 1);
    width: 100%;
  }

  &:hover::before {
    background: $hover-color;
    transform: scaleX(1);
  }
}

// Loading spinner
@mixin loading-spinner($color: $gds-blue, $size: 21px, $thickness: 6) {
  $svg: '<svg viewBox="-#{($thickness / 2)-1} -#{($thickness / 2)-1} #{36 + $thickness} #{36 + $thickness}" xmlns="http://www.w3.org/2000/svg" stroke="#{$color}"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="#{$thickness}"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"></path></g></g></svg>';
  content: "";
  position: absolute;
  height: $size + 1;
  width: $size;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg) ;
  background-image: #{inline-svg($svg)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 1s linear infinite;
}


@keyframes spin { 100% { transform: translate(-50%, -50%) rotate(360deg); } }


// Scale up font sizes... From and to... this is different to using ems
@mixin font-size($desktop, $mobile) {
	$desktop: strip-unit($desktop);
	$mobile: strip-unit($mobile);
	$difference: $desktop - $mobile;

	font-size: ($mobile + $difference) * 1px;

	@media (max-width: $bp-to-desktop-wide) {
		font-size: ($mobile + ($difference * 0.8)) * 1px;
	}

	@media (max-width: $bp-to-desktop) {
		font-size: ($mobile + ($difference * 0.6)) * 1px;
	}

	@media (max-width: $bp-to-tablet) {
		font-size: ($mobile + ($difference * 0.4)) * 1px;
	}

	@media (max-width: $bp-to-tween) {
		font-size: ($mobile + ($difference * 0.2)) * 1px;
	}

	@media (max-width: $bp-to-mobile) {
		font-size: $mobile * 1px;
	}
}

/**
 *
 */
@mixin color-variant($parent, $color) {
  .color-#{$parent} {
    color: $color;
    &--bg {
      background-color: $color
    }
  }
}

@mixin custom-bg-variant($parent, $color, $text) {
  #{$parent} {
    background-color: $color !important;
    // color: $text !important;
  }
  a#{$parent},
  button#{$parent} {
    &:hover,
    &:focus {
      background-color: lighten($color, 10%) !important;
    }
  }
}

@function theme-color($key) {
  @return map-get($colors, $key);
}

@mixin default-button() {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@mixin reset-list() {
  margin: 0;
  padding: 0;
}

@mixin black-background {
  background-color: $color-black;
  color: $color-white;

  a {
    color: $color-white;
    
    &:hover, 
    &:focus {
      color: $color-pink;
    }
  }
}