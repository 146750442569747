.push {
  // transition: .4s ease-in-out;
  > main {
    transition: $transition-default;
    opacity: 1;
    // filter: grayscale(0);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
      //   transform: scale(1, 1);
    // }
  }

  &.opening {
    > main {
      opacity: 0.5;
      // filter: grayscale(100);
      // @media screen and (-webkit-min-device-pixel-ratio:0) {
      //   transform: scale(0.94, 0.94);
      // }
    }
  }

  &.open {
    > main {
      opacity: 0.5;
      filter: grayscale(100);
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        transform: scale(0.94, 0.94);
      }
    }
  }
  
  &-nav {
    
    z-index: -1;
    visibility: hidden;
    transition: $transition-default;
    
    &.transitions {
      &.opening {
        visibility: visible;
        z-index: $zIndex-fixed;
        right: 0;
      }

      &.closing {

      }
    }

    &.open {
      visibility: visible;
      z-index: $zIndex-fixed;
      right: 0;
    }
  }
}