@keyframes tabShowNumber {
  0% {
    right: 0;
    transform: translate(0, 0);
  }
  100% {
    right: 100%;
    transform: translate(32px, 0);
  }
}

@keyframes tabShowLine {
  0% {
    width: 0.01%;
  }
  100% {
    width: 100%;
  }
}

@keyframes tabsFlyOut {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(100%, 0);
    opacity: 0;
  }
}

@keyframes tabsFlyIn {
  0% {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

// @mixin tabs-animation($name) {
//   animation-name: $name;
//   animation-duration: $tabs-animation-duration;
//   animation-iteration-count: $tabs-animation-iteration-count;
//   animation-timing-function: $tabs-animation-timing-function;
// }

.tabs {

  &__row,
  &__left,
  &__right {
    position: relative;
  }

  &__left {
    z-index: 100;
  }

  &__right {
    z-index: 50;
  }

  &__nav-item {
    
    &[role="button"],
    > [role="button"],
    > a {
      &.opening {
        .methods__number {
          > span {
            // @include tabs-animation('tabShowNumber');
            animation-name: tabShowNumber;
            animation-duration: $tabs-animation-duration;
            animation-iteration-count: $tabs-animation-iteration-count;
            animation-timing-function: $tabs-animation-timing-function;
          }

          &:before {
            // @include tabs-animation('tabShowLine');
            animation-name: tabShowLine;
            animation-duration: $tabs-animation-duration;
            animation-iteration-count: $tabs-animation-iteration-count;
            animation-timing-function: $tabs-animation-timing-function;
          }
        }
      }

      &.open {
        .methods__number {

        }
        .header__heading {
          
        }
      }

      &.closing {
        .methods__number {
          > span {
            // @include tabs-animation('tabShowNumber');
            animation-name: tabShowNumber;
            animation-duration: $tabs-animation-duration;
            animation-iteration-count: $tabs-animation-iteration-count;
            animation-timing-function: $tabs-animation-timing-function;
            animation-direction: reverse;
          }
          &:before {
            // @include tabs-animation('tabShowLine');
            animation-name: tabShowLine;
            animation-duration: $tabs-animation-duration;
            animation-iteration-count: $tabs-animation-iteration-count;
            animation-timing-function: $tabs-animation-timing-function;
            animation-direction: reverse;
          }
        }
      }
    }
  }
  &__content {
    position: relative;
    overflow-x: hidden;

    &-item {
      // position: absolute;
      // top: 0;
      // left: 0;
      display: none;
      transform: translate(-100%, 0);
      opacity: 0;
      visibility: hidden;
      backface-visibility: hidden;
      
      &.opening, 
      &.open,
      &.closing {
        display: block;
        visibility: visible;
      }

      &.opening {
        transform: translate(-100%, 0);
        visibility: visible;
        // @include tabs-animation('tabsFlyIn');
        animation-name: tabsFlyIn;
        animation-duration: $tabs-animation-duration;
        animation-iteration-count: $tabs-animation-iteration-count;
        animation-timing-function: $tabs-animation-timing-function;
        opacity: 1;
        // animation-delay: $tabs-animation-duration;
      }

      &.open {
        transform: translate(0, 0);
        opacity: 1;
      }

      &.closing {
        transform: translate(100%, 0);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        // @include tabs-animation('tabsFlyOut');
        animation-name: tabsFlyOut;
        animation-duration: $tabs-animation-duration;
        animation-iteration-count: $tabs-animation-iteration-count;
        animation-timing-function: $tabs-animation-timing-function;
      }
    }
  }
}