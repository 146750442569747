.case {
  &-detail {

    &__header {
      margin-bottom: 100px;
    }

    &__lead {
      font-size: $font-size-large;
      font-weight: $font-weight-light;
    }

    &__teaser {
      margin: 50px 0;
    }

    &__heading {

      &--teaser {
        font-size: $font-size-base;
        margin-bottom: $line-height-base * 1.5rem;
        font-weight: $font-weight-bold;
      }

      &--content {
        font-family: $font-family-secondary;
        font-size: calculateFontSize($font-size-base, $font-size-computed, 50);
        margin-bottom: calculateFontSize($font-size-base, $font-size-computed, 30);
      }
    }

    &__content {
      padding: 100px 0;
    }

    &__image {
      margin-bottom: 70px;
    }
  }
}

.app-stores {
  padding-top: 15vh;
  padding-bottom: 25vh;

  &__links,
  &__content {
    max-width: 50%;
    margin: auto;
  }

  &__content {
    margin-bottom: 15px;
  }

  &__heading {
    font-family: $font-family-primary;
    font-size: calculateFontSize($font-size-base, $font-size-computed, 20);
  }
}