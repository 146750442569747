.logo {
  &__link {
    color: $black;
    font-family: $header-font-family;
    font-size: $header-font-size;
    font-weight: $header-font-weight;
    letter-spacing: 2px;

    .site-header--white & {
      color: $color-white;
    }

    .site-header--scrolled .logo & {
      > span {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(2) {}
        &:nth-child(3) {}
      }
    }
  }
}