html,
body {
  // height: 100%;
}

body {
  // width: 100%;
  // min-height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  // color: $blue;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}