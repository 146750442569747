.blog {
  padding: 20vh 0 30vh 0;
  background-color: $color-grey-lighter;

  // .blog-list
  &-list {
    &__body {
      margin-right: -$grid-gutter-width / 2;
      margin-left: -$grid-gutter-width / 2;
    }

    // .blog-list__item
    &__item {
      width: calc(100%/3);
      &--inner {
        padding: 0 $grid-gutter-width / 2 100px $grid-gutter-width / 2;
      }
    }
  }

  &-overview {
    &__item {

      & + .blog-overview__item {
        margin-top: 15vh;
      }
    }
  }

  
  &-detail {
    padding-bottom: 0;
    background-color: $color-white;
    &__content {

      &--wrap {
        padding: 7vh 0 14vh 0;
      }
    }
    &__meta {
      display: flex;
      padding-bottom: 7vh;
    }

    &__author {
      
      &--image {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        overflow: hidden;
      }
    }

    &__info {
      padding-top: 15px;
      padding-left: 20px;
    }

    &__lead {
      font-size: $font-size-large;
      letter-spacing: 1px;
    }

  }
}

.article {
  // .article__image
  &__image {
    &-link {
      display: block;
    }
  }

  // .article__wrap
  &__wrap {
    padding: 0 $grid-gutter-width / 2 100px $grid-gutter-width / 2;

    .case-detail-latest & {
      padding: 0 0 100px 0;
    }
  }

  // .article__meta
  &__meta {
    display: flex;
    font-size: $font-size-small;
    margin-top: $grid-gutter-width / 2;
  }

  // .article__tags
  &__tags {
    flex-grow: 1;

    // .article__tags > a
    > a {
      color: $color-pink;
      // & + a {
      //   margin-left: -3px;
      // }

      &:hover,
      &:focus {
        opacity: .8;
      }
    }
  }

  // .article__wrap
  &__header {
    margin-top: $grid-gutter-width / 2;
    font-size: $font-size-base;
    font-weight: 300;
    letter-spacing: 1px;
    color: $color-grey-light;

    // .article__wrap-link
    &-link {
      color: $color-primary;
    }
  }
  

  &-overview {
    transition: transform .2s ease;
    transform: translate(0, 0);

    &:hover,
    &:focus {
      transform: translate(5%, 0);
    }

    &__header {
      font-size: calculateFontSize($font-size-base, $font-size-computed, 50);
      margin-top: 0;
      line-height: 1.34;
      font-weight: $font-weight-bold;
      font-family: $font-family-playfair;
    }

    &__meta {
      margin-top: 0;
    }

    &__tags {
      padding-left: $grid-gutter-width;
    }
  }
}