html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  line-height: 1.5;
  color: $color-black;
  letter-spacing: 2px;
}

h1, .h1 { // Hero and main component headings
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  font-size: 6rem;
  line-height: 7.6rem;

  @include media-breakpoint-down(lg) {
    font-size: 5rem;
    font-size: 3.2vw;
    line-height: 1.3;
  }

  &.color--white,
  .color--white & {
    color: $white;
  }
}

// TODO - Add more when pattern shows up