.button {
  position: relative;
  color: $color-black;
  transition: all 150ms ease-in-out;
  padding: 10px 20px;
  border: 0;
  background: transparent;
  font-weight: $font-weight-medium;
  cursor: pointer;
  border-radius: 5px;
  line-height: 20px;

  &.color--white,
  .color--white & {
    color: $color-white;
  }

  &__text {
    position: relative;
    z-index: 100;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100px;
    border: 5px solid $color-pink;
    width: 80px;
    height: 80px;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all 250ms ease-in-out;
    z-index: 50;

    .color--white & {
      color: $color-white;
      border-color: $color-white;
    }
  }

  &:hover, &.active {
    color: $color-white;
    background-color: $color-pink;
    transition-delay: 150ms;

    .color--white & {
      color: $color-black;
      background-color: $color-white;
    }

    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 5px;
      transform: translate(0, -50%);
    }
  }

  &--space {
    margin: 20px 0;
  }

  &--inline {
    top: 30px;
  }
}

a.button--space {
  display: inline-block;
}