.navigation {

  // .navigation__button
  &__button {
    @include default_button();
    @extend .logo__link;
    position: relative;
    z-index: $zIndex-fixed-nav;
    
  // .navigation__button-wrap
    &-wrap {
      text-align: right;
    }

  // .navigation__button--open
    &--open {
      display: block;
      transform: scale(1, 1);
      transition: .35s ease-in;
      
      // .navigation__button--open > span
      > span {
        display: inline-block;
        transition: .35s ease-in;
        transform: translate(0,0);
        opacity: 1;
      }

      // .opening .navigation__button--open
      .opening & {
        transform: scale(.8, .8);
        
        // .opening .navigation__button--open > span
        > span {

          opacity: 0;
          &:nth-child(1) {
            transform: translate(100%, 0);
          }
          &:nth-child(2) {
            transform: translate(100%, 0);
          }
          &:nth-child(3) {
            transform: translate(-100%, 0);
          }
          &:nth-child(4) {
            transform: translate(-100%, 0);
          }
        }
      }
    }

    // .navigation__button--close
    &--close {
      display: none;

      > span {
        display: inline-block;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: -$navigation-width;
    width: $navigation-width;
    height: 100%;
    padding: $navigation-padding;
    background-color: $navigation-bg;
  }

  &__menu {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
}