.grid {
  @include make-container();
  @include make-container-max-widths();

  &--fluid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $grid-gutter-width / 2;
    @include media-breakpoint-up(xl) {
      padding: 0 5%;
    }
  }

  // Responsive containers that are 100% wide until a breakpoint
}

@each $breakpoint, $container-max-width in $container-max-widths {
  .grid--#{$breakpoint} {
    @extend .grid--fluid;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-grid-#{$breakpoint} {
      max-width: $container-max-width;
    }

    @each $name, $width in $grid-breakpoints {
      @if ($container-max-width > $width or $breakpoint == $name) {
        .grid#{breakpoint-infix($name, $grid-breakpoints)} {
          @extend %responsive-grid-#{$breakpoint};
        }
      }
    }
  }
}

.grid {
  &__row {
    @include make-row();
  }
}
.grid__row--no-gutters {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;

  > .grid__col,
  > [class*="grid__col--"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-columns();