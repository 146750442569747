.site-header {
  height: $header-height - $header-padding-top - $header-padding-bottom;
  width: 100%;
  position: fixed;
  margin-bottom: $header-padding-bottom;
  margin-top: $header-padding-top;
  z-index: $zIndex-fixed-nav;
}

.site-header__logo {
  // TODO
}

.site-header__menu {
  // TODO
}