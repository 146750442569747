.about {
  min-height: 100vh;
  padding-top: 20vh;
  
  &__item {
    &--space {
      margin: 20vh 0 30vh 0;
  
      &:first-child {
        margin-top: 0;
      }


      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__methods {
    margin-top: 10vh;
    margin-bottom: 20vh;
  }
}