.site-footer {
  padding: 15vh 0;
  @include black-background();

  // .site-footer__nav
  &__nav {
    
    // ..site-footer__nav + .site-footer__nav
    & + .site-footer__nav {
      margin-top: $grid-gutter-width * 1.5;
    }

    // .site-footer__nav
    &--heading {
      font-size: $font-size-base;
      margin-bottom: 15px;
    }
  }
}

