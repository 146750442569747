// Colors - Base
$color-white: #fff;
$color-black: #000;

$color-grey-lighter: #FAFAFA;
$color-grey-light: #1c1d22;
$color-grey: #8D8D8D;
$color-grey-dark: #444;

$color-pink: #FF455B;
$color-blue: #0166F5;


$colors: (
  'black': $color-black,
  'blue': $color-blue,
  'grey': $color-grey,
  'grey-dark': $color-grey-dark,
  'grey-light': $color-grey-light,
  'pink': $color-pink,
  'white': $color-white,
);

// Colors - Semantic
$color-primary: $color-black;
$color-secondary: $color-pink;
$color-tertiary: $color-grey-dark;


// Fonts
$font-family-poppins: 'Poppins Light', sans-serif;
$font-family-poppins-regular: 'Poppins', sans-serif;
$font-family-playfair: 'Playfair Display', sans-serif;

$font-size-base: 1.8rem;
$font-size-computed: 18;

$font-size-small: calculateFontSize($font-size-base, $font-size-computed, 12);
$font-size-large: calculateFontSize($font-size-base, $font-size-computed, 30);

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// Fonts - Semantic
$font-family-primary: $font-family-poppins;
$font-family-secondary: $font-family-playfair;


// Breakpoints
$bp-to-desktop-widest: 1920px;

$bp-from-desktop-wide: 1621px;
$bp-to-desktop-wide: $bp-from-desktop-wide - 1px;

$bp-from-desktop: 1321px;
$bp-to-desktop: $bp-from-desktop - 1px;

$bp-from-tablet: 1029px;
$bp-to-tablet: $bp-from-tablet - 1px;

$bp-from-tween: 769px;
$bp-to-tween: $bp-from-tween - 1px;

$bp-from-mobile: 481px;
$bp-to-mobile: $bp-from-mobile - 1px;


// Bootstrap Overrides
$grid-columns: 12;
$grid-gutter-width: 36px;
$grid-row-columns: 6;

$tabs-animation-duration: .3s;
$tabs-animation-iteration-count: 1;
$tabs-animation-timing-function: cubic-bezier(.2, 0.7, 1, 0.7);

// Bootstrap Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: $bp-from-mobile,      // 480 px
  tw: $bp-from-tween,       // 768 px
  md: $bp-from-tablet,      // 1028 px
  lg: $bp-from-desktop,     // 1320 px
  xl: $bp-from-desktop-wide // 1620 px
);

// Bootstrap Containers
$container-max-widths: (
  sm: $bp-from-mobile - $grid-gutter-width,                //  480 - 36 =  444px
  tw: $bp-from-tween - $grid-gutter-width - 166px,                 //  768 - 36 =  732px
  md: $bp-from-tablet - $grid-gutter-width - 166px,                // 1028 - 36 =  992px
  lg: $bp-from-desktop - $grid-gutter-width - 166px,               // 1320 - 36 = 1284px
  xl: $bp-from-desktop-wide - ($grid-gutter-width * 1.5)   // 1620 - 54 = 1566px
);

$distance-values-percentage: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);

$utilities: () !default;

$utilities: map-merge(
  (
    "display": (
      responsive: true,
      print: true,
      property: display,
      class: display,
      values: none inline inline-block block table table-row table-cell flex inline-flex
    ),
    "align": (
      property: vertical-align,
      class: align,
      values: baseline top middle bottom text-bottom text-top
    ),
    "float": (
      responsive: true,
      property: float,
      values: left right none
    ),
    "overflow": (
      property: overflow,
      values: auto hidden,
    ),
    "justify": (
      property: justify,
      values: center left right
    ),
    "width": (
      property: width,
      class: width,
      values: $distance-values-percentage
    ),
    "max-width": (
      property: max-width,
      class: mw,
      values: (100: 100%)
    ),
    "viewport-width": (
      property: width,
      class: vw,
      values: (100: 100vw)
    ),
    "min-viewport-width": (
      property: min-width,
      class: min-vw,
      values: (100: 100vw)
    ),
    "height": (
      property: height,
      class: height,
      values: $distance-values-percentage
    ),
    "max-height": (
      property: max-height,
      class: mh,
      values: (100: 100%)
    ),
    "viewport-height": (
      property: height,
      class: vh,
      values: (100: 100vh)
    ),
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (100: 100vh)
    ),
    "text": (
      property: text-align,
      class: text,
      values: left center right
    ),
    // Flex utilities
    "flex": (
      responsive: true,
      property: flex,
      values: (fill: 1 1 auto)
    ),
    "flex-direction": (
      responsive: true,
      property: flex-direction,
      class: flex,
      values: row column row-reverse column-reverse
    ),
    "flex-grow": (
      responsive: true,
      property: flex-grow,
      class: flex,
      values: (
        grow-0: 0,
        grow-1: 1,
      )
    ),
    "flex-shrink": (
      responsive: true,
      property: flex-shrink,
      class: flex,
      values: (
        shrink-0: 0,
        shrink-1: 1,
      )
    ),
    "flex-wrap": (
      responsive: true,
      property: flex-wrap,
      class: flex,
      values: wrap nowrap wrap-reverse
    ),
    "justify-content": (
      responsive: true,
      property: justify-content,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: space-around,
      )
    ),
    "align-items": (
      responsive: true,
      property: align-items,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch,
      )
    ),
    "align-content": (
      responsive: true,
      property: align-content,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: space-around,
        stretch: stretch,
      )
    ),
    "align-self": (
      responsive: true,
      property: align-self,
      values: (
        auto: auto,
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch,
      )
    ),
    "order": (
      responsive: true,
      property: order,
      values: (
        first: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        last: 6,
      ),
    ),
  ),
  $utilities
);

// Z-Index
$zIndex-fixed: 1500;
$zIndex-fixed-nav: $zIndex-fixed + 100;

// Transitions
$transition-default: 0.4s ease-in;


// ELEMENTS
// --

// Navigation
$navigation-bg: $color-pink;
$navigation-color: $color-white;
$navigation-font-family: $font-family-poppins-regular;
$navigation-font-sm: $font-family-playfair;
$navigation-font-size-base: calculateFontSize($font-size-base, $font-size-computed, 36); // 3.6rem
$navigation-font-size-sm: calculateFontSize($font-size-base, $font-size-computed, 16); // 3.6rem
$navigation-line-height: 54px;
$navigation-line-height-sm: 21px;
$navigation-padding: 0 150px;
$navigation-width: 49%;

$navigation-sub-font-size-base: 20px;

// Site header
$header-padding-top: $grid-gutter-width / ($grid-gutter-width / 70px);
$header-padding-bottom: $header-padding-top / 2;
$header-line-height: 33px;
$header-height: $header-padding-top + $header-padding-bottom + $header-line-height;
$header-font-family: $font-family-poppins-regular;
$header-font-size: calculateFontSize($font-size-base, $font-size-computed, 22);
$header-font-weight: $font-weight-medium + 100; // 600


