.header {
  &--center {
    text-align: center;
  }
  
  &__heading {
    position: relative;
    padding-top: 2.2rem;
  
    &--space {
      margin-bottom: 6rem;
    }

    &--no-space {
      margin: 0;
      padding: 0;
    }
    
    &--outline {
      font-family: $font-family-primary;
      letter-spacing: 1px;
      color: $white;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      line-height: 70px;
    }
  }
  
  &__preheading {
    position: absolute;
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    font-family: $font-family-primary;
    top: 0;
    left: 0;
    right: 0;
    line-height: 1.5;
  }
  
}

