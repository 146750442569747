@mixin row-cols($count) {
  & > * {
    flex: 0 0 100% / $count;
    max-width: 100% / $count;
  }
}

@mixin make-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: bem-breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width -below their breakpoints
    @for $i from 1 through $columns {
      .grid__col#{$infix}--#{$i} {
        @extend %grid-column;
      }
    }
    .grid__col#{$infix},
    .grid__col-#{$infix}--auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.grid__col-{bp}` classes for equal-width flexbox columns
      .grid__col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @for $i from 1 through $grid-row-columns {
        .grid__row__cols#{$infix}--#{$i} {
          @include row-cols($i);
        }
      }

      @for $i from 1 through $columns {
        .grid__col#{$infix}--#{$i} {
          @include make-col($i, $columns);
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .grid__offset#{$infix}--#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}