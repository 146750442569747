.content {
  letter-spacing: 1px;

  p, 
  address {
    font-family: $font-family-poppins;
    margin-bottom: $font-size-base * 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-pink;

    &:hover, 
    &:focus {
      color: darken($color-pink, 30);
    }
  }
  
  &__headline {
    &--main {
      font-family: $font-family-playfair;
      font-size: $font-size-large;
      margin-bottom: $font-size-base * 1.5;
      margin-top: $font-size-base * 3;

      &:first-child {
        margin-top: 0;
      }
    }

    &--sub {
      margin-top: $font-size-base * 3;
      margin-bottom: $font-size-base * 1.5;
    }
  }

  &__image {
    text-align: right;
    padding-top: $font-size-base * 3;
    padding-bottom: $font-size-base * 1.5;
    
    &:before,
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }

    > img {
      display: inline-block;
      float: right;
    }
  }
}