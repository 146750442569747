.list {
  @include reset-list();

  &-border {
    
    &__item {
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;

      & + li {
        border-top: 1px solid rgba(0, 0, 0, .15);

        .color-white & {
          border-color: $white;
        }
      }

      &--title {
        position: relative;
        padding-left: 70px;
      }
    }

    &__title {
      position: absolute;
      // top: 0;
      left: 0;
      font-weight: $font-weight-bold;
    }

  }
}

.directory {
  @include reset-list();
  
  &__title,
  &__value {
    display: block;
    margin: 0;
    margin-inline-start: 0;
  }

  &__title {
    color: $color-grey;

    
    .color-white & {
      color: $white;
      opacity: 0.7;
    }
  }
  
  &__value {
    + .directory__title {
      margin-top: 30px;
    }
  }

  &-horizontal {
    padding: 0;
    margin-bottom: $font-size-base * 1.5;

    &:before,
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }

    &__title,
    &__value {
      display: block;
      margin: 0;
      margin-inline-start: 0;
      float: left;
    }

    &__title {
      width: 25%;
    }

    &__value {
      width: 75%;
    }

    &__link {
      color: $color-primary;
    }
  }
}