
// Animation for the main navigation links
@keyframes nav-link-hover {
  0% {
    height: 3px;
    width: 0%;
    background-color: rgba($white, 1)
  }

  45% {
    height: 3px;
    width: 100%;
    background-color: rgba($white, 0.3)
  }

  100% {
    height: 20px;
    width: 100%;
    background-color: rgba($white, 0.3)
  }
}

.nav {
  // Reset margin and padding of the basic <UL>
  @include reset-list();

  // Navigation <ListItem>
  &__item {
    display: block;
    position: relative;

    // Assign distance to top if there is a second element following
    & + .nav__item {
      margin-top: 46px;
    }
  }

  &__link {
    display: block;
    color: $navigation-color;
    font-family: $navigation-font-family;
    font-size: $navigation-font-size-base;
    line-height: $navigation-line-height;
    
    &--small {
      display: block;
      position: relative;
      top: 3px;
      font-family: $navigation-font-sm;
      font-size: $navigation-font-size-sm;
      line-height: $navigation-line-height-sm;
    }
  }
  
  &__text {
    position: relative;

    &:after {
      content: ' ';
      display: block;
      height: 1px;
      width: 0%;
      position: absolute;
      bottom: 10%;
      left: 0;
      background-color: rgba($white, 1);
      transition: opacity .4s ease;

      .nav__item:hover &,
      .nav__item:focus &,
      .nav__item--active & {
        height: 20px;
        width: 100%;
        background-color: rgba($white, 0.3)
      }

      .nav__item:hover &,
      .nav__item:focus & {
        animation-name: nav-link-hover;
        animation-duration: .4s;
        animation-iteration-count: 1;
      }

      .nav__item--active:hover & {
        animation: none;
      }
    }
  }

  &-sub {
    @include reset-list();
    visibility: hidden;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: -1;
    padding-top: 45px;
    padding-left: 115px;
    opacity: 0;
    transition-duration: .25s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-delay: .4s;

    .nav__item:hover &,
    .nav__item:focus & {
      visibility: visible;
      z-index: inherit;
      opacity: 1;
      transition-delay: 0;
    }

    .nav__item:not(:hover) & {
      transition-delay: 0;
    }

    &__item {
      display: block;

      & + .nav-sub__item {
        margin-top: 30px;
      }
    }

    &__link {
      color: $navigation-color;
      font-family: $navigation-font-family;
      font-size: $navigation-sub-font-size-base;
      line-height: $navigation-sub-font-size-base;
      opacity: 1;

      .nav-sub__item:hover &,
      .nav-sub__item:focus & {
        opacity: 0.8;
      }
    }
  }

  // .nav-footer
  &-footer {
    @include reset-list();

    // .nav-footer__item
    &__item {

      // .nav-footer__item--inline
      &--inline {
        display: inline;

        // .nav-footer__item--inline::after
        &:after {
          content: ', ';
          margin-left: -7px;
        }

        // .nav-footer__item--inline::last-child::after
        &:last-child:after {
          display: none;
        }
      }

      // .nav-footer__item--social
      &--social {
        display: inline-block;
        width: 20px;
        height: 20px;

        // .nav-footer__item--social::after
        &:after {
          display: none;
        }

      // .nav-footer__item--social + .nav-footer__item--social
      & + .nav-footer__item--social {
          margin-left: $grid-gutter-width / 2;
        }
      }
    }

    // .nav-footer__link
    &__link {
      color: $white;
      font-weight: 300;

      // .nav-footer__lin:hover, .nav-footer__link:focus
      &:hover, 
      &:focus {
        color: $color-pink;
      }

      // .nav-footer__link--social
      &--social {
        display: block;
        width: 20px;
        height: 20px;
        text-align: center;
      }
    }
  }

  // .nav-meta
  &-meta {
    @include reset-list();

    // .nav-meta__item
    &__item {
      display: inline-block;

      & + .nav-meta__item {
        margin-left: $grid-gutter-width;
      }
    }

    // .nav-meta__link
    &__link {

    }
  }
}
